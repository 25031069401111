import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LikeDislikeQueryState } from './likeDislike.state';

export const LIKEDISLIKE_STATE_NAME = 'likeDislike';
const selectLikeDislikeState = createFeatureSelector<LikeDislikeQueryState>(
  LIKEDISLIKE_STATE_NAME
);
export const selectLikeDislikeCount = createSelector(
  selectLikeDislikeState,
  state => state.total
);
const selectLikeDislikeQuery = createSelector(
  selectLikeDislikeState,
  ({ data }) => {
    return data;
  }
);
const selectFilter = createSelector(
  selectLikeDislikeState,
  ({ filter }) => filter
);
const selectPagination = createSelector(
  selectLikeDislikeState,
  ({ pagination }) => pagination
);

const selectPaginationAndFilter = createSelector(
  selectLikeDislikeState,
  ({ pagination, filter }) => ({ filter, pagination })
);
const selectTotal = createSelector(
  selectLikeDislikeState,
  ({ total }) => total
);
export default {
  selectFilter,
  selectLikeDislikeQuery,
  selectTotal,
  selectPagination,
  selectPaginationAndFilter,
};
