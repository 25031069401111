import {
  ApiResponse,
  Pagination,
  likeDislikeQuery,
  likeDisLikePageModel,
} from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const likeDislikeActions = createActionGroup({
  source: 'LikeDislikeQuery page',
  events: {
    'fetch LikeDislikeQuery': emptyProps(),
    'fetch likeDislikeQuery success': props<{
      data: ApiResponse<likeDislikeQuery[]>;
    }>(),
    'update filter': props<{ data: likeDisLikePageModel.filter }>(),
    'update Pagination': props<{ data: Pagination }>(),
    'archive likeDislike': props<{ id: likeDislikeQuery['id'] }>(),
  },
});
export default likeDislikeActions;
